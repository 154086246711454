<template>
  <div class="contact">
    <div class="category pc">
      <img src="../assets/img/logo/logo-category.svg" alt="" />
      <a class="before" href="#">Trang chủ</a>
      <span class="tag-mid">/</span>
      <a class="after" href="#">Liên Hệ</a>
    </div>
    <div class="contact-main">
      <div class="contact-main__title" data-aos="fade-up">
        <h5>{{ trans("frontend.form.title.contact_us") }}</h5>
        <h1>{{ trans("frontend.form.title.fill_contact") }}</h1>
      </div>
      <div class="contact-main__content">
        <div class="info" data-aos="fade-up">
          <div class="item">
            <div class="item__icon">
              <img src="../assets/img/contact/2.svg" alt="" />
            </div>
            <div class="item__content">
              <h3 class="title">{{ trans("frontend.call us") }}</h3>
              <p class="sub">Hotline: {{ info.hotline_1 }}</p>
            </div>
          </div>
          <div class="item">
            <div class="item__icon">
              <img src="../assets/img/contact/1.svg" alt="" />
            </div>
            <div class="item__content">
              <h3 class="title">E-mail</h3>
              <p class="sub">{{ info.email }}</p>
            </div>
          </div>
          <div class="item">
            <div class="item__icon">
              <img src="../assets/img/contact/3.svg" alt="" />
            </div>
            <div class="item__content">
              <h3 class="title">Amber Hills Golf & Resort</h3>
              <p class="sub">{{ info.address }}</p>
            </div>
          </div>
        </div>
        <form
          @submit.prevent="submitContact"
          data-aos="fade-up"
          ref="formContact"
        >
          <div class="info">
            <input
              type="text"
              name=""
              id=""
              :placeholder="trans('frontend.form.nick_name')"
              v-model="form.nick_name"
            />
            <input
              type="text"
              name=""
              id=""
              v-model="form.full_name"
              :placeholder="trans('frontend.form.full_name')"
              required
            />
            <input
              type="text"
              name=""
              id=""
              v-model="form.phone"
              :placeholder="trans('frontend.form.phone')"
              required
            />
            <input
              type="text"
              name=""
              id=""
              :placeholder="trans('frontend.form.email')"
              v-model="form.email"
              required
            />
          </div>
          <div class="content">
            <textarea
              type="text"
              name=""
              id=""
              :placeholder="trans('frontend.form.content')"
              v-model="form.content"
            />
          </div>
          <div class="submit">
            <p class="note">{{ trans("frontend.form.required") }}</p>
            <button type="submit">
              <img src="../assets/img/icons/arrow-right-white.svg" alt="" />
              <span>{{ trans("frontend.button.send to") }}</span>
            </button>
          </div>
        </form>
      </div>
      <div class="contact-main__map" data-aos="fade-up">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17692.74485142326!2d106.17965702411932!3d21.21433711520837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313572e415727eb9%3A0xc0bcd3112cbb4f85!2sS%C3%A2n%20Golf%20Amber%20Hills!5e0!3m2!1svi!2s!4v1647874241214!5m2!1svi!2s"
          width="600"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
/* global $ */
import ContactService from "@/services/ContactService";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      category: "Tin Tức",
      title: "Những Câu CHuyện Mới Nhất Trong Làng Golf",
      type: 0,
      submit: false,
      message: "GỬI ĐI",
      form: {
        email: "",
        phone: "",
        full_name: "",
        nick_name: "",
        content: "",
        type: 1,
      },
    };
  },
  mounted: function () {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.$nextTick(function () {
      $("#tab-menu-member li").click(function (event) {
        $("#tab-menu-member li").removeClass("active");
        event.target.classList.toggle("active");
      });
    });
  },
  computed: {
    ...mapGetters(["info"]),
  },
  methods: {
    submitContact() {
      let formDatas = new FormData();
      for (var key in this.form) {
        formDatas.append(key, this.form[key]);
      }
      ContactService.sendContact(formDatas)
        .then(() => {
          this.submit = true;
          this.$refs.formContact.reset();
          this.$toast.fire({
            icon: "success",
            title: "Gửi Thành Công",
          });
        })
        .catch(() => {
          this.submit = false;
          this.$toast.fire({
            icon: "error",
            title: "Gửi Thất Bại",
          });
        });
    },
  },
};
</script>
